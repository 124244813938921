import React, { useEffect, useReducer, createContext, Suspense } from "react"

import { format } from "date-fns"
import PgaTourStroke from "./pga-tour-stroke"
import PageLoading from "../layout/page-loading"

const PgaTourMatch = React.lazy(() => import("./pga-tour-match"))

const TournamentContext = createContext("tournament")

const initialState = {
  tournamentFormat: "stroke",
  tournamentName: "",
  tournamentCourse: "",
  tournamentTimeZone: null,
  tournamentStartDate: "",
  tournamentEndDate: "",
  tournamentLocation: "",
  tournamentId: null,
  localTimeZone: null,
}

function reducer(state, action) {
  switch (action.type) {
    case "setTournament":
      return {
        tournamentFormat: action.payload.tournamentFormat,
        tournamentName: action.payload.tournamentName,
        tournamentCourse: action.payload.tournamentCourse,
        tournamentTimeZone: action.payload.tournamentTimeZone,
        tournamentStartDate: action.payload.tournamentStartDate,
        tournamentEndDate: action.payload.tournamentEndDate,
        tournamentLocation: action.payload.tournamentLocation,
        tournamentId: action.payload.tournamentId,
        localTimeZone: action.payload.localTimeZone,
      }
    default:
      return state
  }
}

const getActiveTournament = async () => {
  const query = process.env.GATSBY_API_LOCAL
    ? "https://cors-anywhere.herokuapp.com/https://www.golfchannel.com/api/v2/events/GetWidgetEvents/7"
    : "/api/pga/schedule"

  const response = await fetch(query)
  const data = await response.json()

  const events = data.events.filter((event) =>
    event.tours.some((tour) => tour.acronym === "PGATOUR")
  )

  const event = events[0]
  // events[
  //   closestIndexTo(
  //     new Date(),
  //     events.map((tourn) => new Date(tourn.EndDate))
  //   )
  // ]

  const tournamentFormat = "stroke"
  const tournamentName = event.name
  const tournamentCourse = event.golfClubs[0].name
  const tournamentLocation = `${event.golfClubs[0].city}, ${event.golfClubs[0].stateAbbreviation}`
  const tournamentId = event.key.toString()
  const tournamentStartDate = format(new Date(event.startDate), "yyyy-mm-dd")
  const tournamentEndDate = format(new Date(event.endDate), "yyyy-mm-dd")

  return {
    tournamentFormat,
    tournamentName,
    tournamentCourse,
    tournamentLocation,
    tournamentId,
    tournamentStartDate,
    tournamentEndDate,
  }
}

const PgaTour = () => {
  const todaysDate = format(new Date(), "MM/dd/yyyy")

  const [state, dispatch] = useReducer(reducer, initialState)

  const reloadApp = () => {
    getActiveTournament().then((tournament) => {
      dispatch({
        type: "setTournament",
        payload: tournament,
      })
    })
  }

  useEffect(() => {
    getActiveTournament().then((tournament) => {
      dispatch({
        type: "setTournament",
        payload: tournament,
      })
    })
  }, [])

  if (state.tournamentId === null) {
    return <PageLoading />
  }

  return (
    <TournamentContext.Provider
      value={{
        reload: () => reloadApp(),
        localTimeZone: state.localTimeZone,
        tournamentTimeZone: state.tournamentTimeZone,
      }}
    >
      {state.tournamentFormat === "match" ? (
        <Suspense fallback={<div>Loading ...</div>}>
          <PgaTourMatch
            name={state.tournamentName}
            course={state.tournamentCourse}
            location={state.tournamentLocation}
            tid={state.tournamentId}
            today={todaysDate}
          />
        </Suspense>
      ) : (
        <PgaTourStroke
          name={state.tournamentName}
          course={state.tournamentCourse}
          location={state.tournamentLocation}
          tid={state.tournamentId}
          today={todaysDate}
          start={state.tournamentStartDate}
          end={state.tournamentEndDate}
        />
      )}
    </TournamentContext.Provider>
  )
}

export { PgaTour, TournamentContext }
