import { isBefore, parse } from "date-fns"

const formatScore = score => {
  if (score === null) {
    return ""
  }
  if (score === 0) {
    return "E"
  }
  if (score > 0) {
    return `+${score}`
  }
  return score.toString()
}

const formatPosition = position => {
  if (!position) {
    return "--"
  }
  if (position === "WITHDRAWN") {
    return "WD"
  }
  if (position === "DISQUALIFIED") {
    return "DQ"
  }
  if (position === "DID NOT START") {
    return "DNS"
  }

  return position
}

const parseTeeTime = time => parse(time.slice(0, -3), "p", new Date())

export const fetchData = async tournamentId => {
  const query = process.env.GATSBY_API_LOCAL
    ? `https://cors-anywhere.herokuapp.com/https://www.golfchannel.com/api/v2/events/${tournamentId}/leaderboard`
    : `/api/pga/leaderboard/${tournamentId}`

  const response = await fetch(query)
  const data = await response.json()

  const currentRound = data.result.roundStatus.currentRound

  let players = data.result.golfers
    .map(player => {
      const activePlayer = player.status === ""
      const playerScorecard = data.result.scorecards.find(
        c =>
          c.golferId === player.golferId &&
          data.result.roundStatus.currentRound === c.round
      )

      return {
        player_id: player.golferId.toString(),
        current_position:
          player.position && player.position.includes("999")
            ? formatPosition(player.status.toUpperCase())
            : player.position === null
            ? ""
            : player.position,
        status: player.status, // need to map id value to something meaningful
        total: formatScore(player.overallPar),
        totalRaw: player.overallPar,
        sortHelp: player.sortHelp,
        totalUnderPar: player.overallPar < 0,
        today:
          activePlayer || currentRound === 2
            ? formatScore(player.todayPar)
            : "--",
        todayUnderPar:
          activePlayer || (currentRound === 2 && player.todayPar !== null)
            ? player.todayPar < 0
            : false,
        thru:
          activePlayer || (currentRound === 2 && player.thruHole !== null)
            ? player.thruHole
            : "",
        startHole: player.startHole,
        teeTime: player.teeTime,
        showTeeTime: player.thruHole === null && player.status === "",
        isActive: activePlayer,
        scorecard: playerScorecard
          ? {
              in: playerScorecard.in,
              out: playerScorecard.out,
              scores: playerScorecard.scores.map(score => ({
                hole: score.hole,
                par: score.par,
                score: parseInt(score.score, 10) || "",
              })),
            }
          : null,
        player_bio: {
          first_name: player.firstName,
          short_name: player.firstName.substring(0, 1).toUpperCase(),
          last_name: player.lastName,
          is_amateur: player.isAmateur,
        },
      }
    })
    .sort((a, b) => {
      if (
        a.sortHelp !== null &&
        a.sortHelp !== 999 &&
        b.sortHelp !== null &&
        b.sortHelp !== 999
      ) {
        return 0
      }
      if (
        (a.sortHelp === 999 || a.sortHelp === null) &&
        b.sortHelp !== 999 &&
        b.sortHelp !== null
      ) {
        return 1
      }
      if (
        (b.sortHelp === 999 || b.sortHelp === null) &&
        a.sortHelp !== 999 &&
        a.sortHelp !== null
      ) {
        return -1
      }
      if (a.status !== "Cut" && b.status === "Cut") {
        return 1
      }
      if (a.status === "Cut" && b.status === "Cut") {
        if (a.totalRaw < b.totalRaw) {
          return -1
        }
        if (a.totalRaw > b.totalRaw) {
          return 1
        }
      }
      if (a.totalRaw && !a.showTeeTime < b.totalRaw && !b.showTeeTime) {
        return -1
      }
      if (a.totalRaw && !a.showTeeTime > b.totalRaw && !b.showTeeTime) {
        return 1
      }
      if (
        a.showTeeTime &&
        b.showTeeTime &&
        a.teeTime === b.teeTime &&
        !isNaN(a.startHole)
      ) {
        if (parseInt(a.startHole, 10) < parseInt(b.startHole, 10)) {
          return -1
        }
        return 1
      }
      if (
        a.showTeeTime &&
        b.showTeeTime &&
        isBefore(parseTeeTime(a.teeTime), parseTeeTime(b.teeTime))
      ) {
        return -1
      }
      if (
        a.showTeeTime &&
        b.showTeeTime &&
        !isBefore(parseTeeTime(a.teeTime), parseTeeTime(b.teeTime))
      ) {
        return 1
      }
      if (parseInt(a.startHole, 10) - parseInt(b.startHole, 10)) {
        return 1
      }
    })

  const showprojectedcut =
    currentRound === 2 &&
    data.result.cutlineScore !== null &&
    data.result.roundStatus.status !== "Final"

  const activePlayers = players.filter(p => p.status === "")
  const totalHoles = activePlayers.length * 18
  const totalCompleted = activePlayers
    .filter(p => p.thru !== "--")
    .reduce((total, player) => {
      return total + player.thru
    }, 0)

  if (showprojectedcut) {
    const cutscore = data.result.cutlineScore

    const aboveCutLine = player =>
      cutscore >= 0
        ? player.overallPar > cutscore
        : player.overallPar === cutscore + 1

    // use raw data since we had formated total score of "0" -> "E" earlier
    const cutIndex = data.result.golfers.findIndex(aboveCutLine)

    players = [
      ...players.slice(0, cutIndex),
      { player_id: "cutLine", score: cutscore },
      ...players.slice(cutIndex),
    ]
  }

  return {
    started:
      data.result.roundStatus.statusId !== 1 ||
      data.result.roundStatus.currentRound > 1, // todo need to figure out what statusId tranlsate to
    players: players,
    currentRound: currentRound,
    roundState: data.result.roundStatus.status,
    percentComplete: (totalCompleted / totalHoles) * 100,
  }
}
