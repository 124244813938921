import PropTypes from "prop-types"
import React, { useReducer, useEffect, useContext, useState } from "react"

import { format, isAfter, getMonth } from "date-fns"

import LeaderboardStroke from "../leaderboard-stroke/leaderboard-stroke"

import { TournamentContext } from "./pga-tour"
import { RootContext } from "../root-provider"

import PageLoading from "../layout/page-loading"
import TournamentHeader from "../tournament/tournament-header"

import { addFavorite, removeFavorite, getFavorites } from "../../util/favorites"

import { useInterval } from "../../util/useInterval"

import { fetchData } from "../../util/golfchannel.stroke"

const initialState = {
  started: false,
  players: [],
  favorites: [],
  roundState: "",
  currentRound: 0,
  percentComplete: 0,
  favoritesEnabled: true,
  loading: true,
  error: false,
}

function reducer(state, action) {
  switch (action.type) {
    case "updateLeaderboard":
      return {
        ...state,
        started: action.payload.started,
        currentRound: action.payload.currentRound,
        players: action.payload.players,
        loading: false,
        roundState: action.payload.roundState,
        percentComplete: action.payload.percentComplete,
        error: false,
      }
    case "showLoader":
      return { ...state, loading: true }
    case "getFavorites":
      return { ...state, favorites: action.payload }
    case "addFavorite":
      return { ...state, favorites: state.favorites.concat(action.payload) }
    case "removeFavorite":
      return {
        ...state,
        favorites: state.favorites.filter(f => f.key !== action.payload),
      }
    case "updateFavorite":
      return {
        ...state,
        favorites: state.favorites.map(f =>
          f.key === action.payload.key ? { ...f, ...action.payload } : f
        ),
      }
    case "disableFavorites":
      return {
        ...state,
        favoritesEnabled: false,
      }
    case "updateError":
      return {
        ...state,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

const PgaTourStroke = ({ name, course, location, tid, today, start, end }) => {
  const tournamentContext = useContext(TournamentContext)
  const rootContext = useContext(RootContext)

  const [state, dispatch] = useReducer(reducer, initialState)

  const [isRefreshRunning, setRefreshRunning] = useState(true)

  const currentYear = "2021"

  const handleFavoriteChange = (playerId, firstName, lastName, operation) => {
    if (operation === "add") {
      addFavorite(playerId, firstName, lastName, "pga", dispatch)
    }
    if (operation === "delete") {
      const player = state.favorites.find(p => p.pga === playerId)
      if (player) {
        removeFavorite(player, dispatch)
      }
    }
  }

  const loadData = (tid, currentYear) => {
    dispatch({ type: "showLoader" })
    rootContext.updateLoading(true)
    fetchData(tid, currentYear)
      .then(data => {
        dispatch({
          type: "updateLeaderboard",
          payload: data,
        })
        rootContext.updateLoading(false)
      })
      .catch(() => {
        dispatch({
          type: "updateError",
        })
        rootContext.updateLoading(false)
      })
  }

  useEffect(() => {
    if (window.indexedDB) {
      getFavorites().then(val => {
        dispatch({
          type: "getFavorites",
          payload: val || [],
        })
      })
    } else {
      dispatch({
        type: "disableFavorites",
      })
    }
  }, [])

  useEffect(() => {
    loadData(tid, currentYear)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid, currentYear])

  useInterval(
    () => {
      loadData(tid, currentYear)
    },
    isRefreshRunning ? 60000 : null
  )

  useEffect(() => {
    const checkVisibilityState = () => {
      if (document.visibilityState === "visible") {
        if (today !== format(new Date(), "MM/dd/yyyy")) {
          tournamentContext.reload()
          if (window.ga) {
            window.ga("send", "event", "App view", "different day")
          }
        } else {
          loadData(tid, currentYear)
          setRefreshRunning(true)
          if (window.ga) {
            window.ga("send", "event", "App view", "same day")
          }
        }
      } else {
        setRefreshRunning(false)
      }
    }
    document.addEventListener("visibilitychange", checkVisibilityState)
    return () => {
      document.removeEventListener("visibilitychange", checkVisibilityState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid, today, tournamentContext, currentYear])

  if (state.loading && state.players.length === 0) {
    return <PageLoading />
  }
  if (state.error || (!state.loading && state.players.length === 0)) {
    return (
      <div className="page-inner-padding">
        <TournamentHeader name={name} course={course} location={location} />

        {start !== "" && isAfter(new Date(start), new Date()) ? (
          <div>
            <p>
              {format(new Date(start), "MMMM d")}
              {getMonth(new Date(start)) === getMonth(new Date(end))
                ? `-${format(new Date(end), "d")}`
                : ` - ${format(new Date(end), "MMMM  d")}`}
            </p>
            <p>
              <span>-- tournament not started yet --</span>
            </p>
          </div>
        ) : (
          <p>-- scoring data not available --</p>
        )}
      </div>
    )
  }

  const pgaFavoriteIds = state.favorites.filter(p => p.pga).map(p => p.pga)

  return (
    <>
      <header className="page-inner-padding">
        <TournamentHeader name={name} course={course} location={location} />
      </header>

      <section>
        {state.players.length > 0 && (
          <LeaderboardStroke
            players={state.players}
            started={state.started}
            status={state.roundState}
            favorites={pgaFavoriteIds}
            favoritesEnabled={state.favoritesEnabled}
            onFavoriteChange={handleFavoriteChange}
          />
        )}
      </section>
    </>
  )
}

PgaTourStroke.propTypes = {
  name: PropTypes.string,
  location: PropTypes.string,
  course: PropTypes.string,
  tid: PropTypes.string,
  today: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
}

PgaTourStroke.defaultProps = {
  name: "",
  course: "",
  location: "",
  tid: "",
  today: "",
  start: "",
  end: "",
}

export default PgaTourStroke
